export const dateRangeParser = (dateRange = []) => {
    // example formate '2023-11-09 ~ 2023-11-23'
    const tempDate = JSON.parse(JSON.stringify(dateRange))
// console.log(dateRange,'daterange')
    if (dateRange.length > 0) {
        const d1 = dateParser(tempDate[0])
        const d2 = dateParser(tempDate[1])
        return `${d1} ~ ${d2}`
    }
    return ''
}

export const dateParser = (date) => {
    if (date && date !== 'null') {
        const tempDate = new Date(date)
        // example formate '2023-11-09'
        const y = tempDate.getFullYear()
        const m = (tempDate.getMonth() + 1).toString().padStart(2, '0')
        const d = tempDate.getDate().toString().padStart(2, '0')
        return `${y}-${m}-${d}`
    }
    return ''
}


export const showText = (row, col) => {
    if (col?.method) {
      return col.method(row[col?.accessorKey], row)
    }
  
    if (!row[col?.accessorKey] && row[col?.accessorKey] !== false) {
      return '--'
    }
  
    return row[col?.accessorKey]
  }