import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from './Firebase.js';
import { doc, getDoc } from "firebase/firestore";
import { SUPERVISOR } from "constants/app-roles.constant.js";

export async function apiSignIn(data) {
    return await signInWithEmailAndPassword(auth, data.userName, data.password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            const payload = user.accessToken;

            const userId = user.uid;

            const docRef = doc(db, "user_profile", userId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                var userObj = {
                    avatar: '',
                    userId:userId,
                    userName: userData.name,
                    userNameA: userData.name_a,
                    email: userData.email,
                    phone: userData.phone,
                    vendorId: userData.vendor_id,
                    chains:userData.chains || [],
                    channels:userData.channels || [],
                    cities:userData.cities || [],
                    classifications:userData.classifications || [],
                    authority: userData.authority || [SUPERVISOR],
                }
                var response = {};
                response["user"] = userObj;
                response["token"] = payload;

                return {
                    code: 200,
                    data: response ,
                    message: `Success`
                }

            } else {
                throw new Error("User is not exist");
            }
        })
        .catch((error) => {
            throw new Error("Invalid Credentials");
        });
}

export async function apiSignUp(data) {
    return null
}

export async function apiSignOut(data) {
    return true;
}

export async function apiForgotPassword(data) {
    return null
}

export async function apiResetPassword(data) {
    return null
}
