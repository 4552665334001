import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrXUNqzWFEYTOXkPUvPctGiZfpGQWQyH0",
  authDomain: "mr-retailer.firebaseapp.com",
  databaseURL: "https://mr-retailer.firebaseio.com",
  projectId: "mr-retailer",
  storageBucket: "mr-retailer.appspot.com",
  messagingSenderId: "45092210537",
  appId: "1:45092210537:web:eb7e301508a73ff73f1ff8",
  measurementId: "G-GJZN76GZVZ"
};

const app = initializeApp(firebaseConfig);

// Firebase Authentication instance
const auth = getAuth(app);
const db = getFirestore(app);

export {
  auth,
  db
}