import { baseUrl } from "env"

const appConfig = {
    apiPrefix: baseUrl,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig

