import React, { createContext, useEffect, useState } from 'react'
import { dateRangeParser } from 'utils/helper'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
    const [isDate, setDate] = useState(true)
    const [isRegion, setRegion] = useState(true)
    const [isCity, setCity] = useState(true)
    const [isAccount, setAccount] = useState(true)
    const [isChannel, setChannel] = useState(true)
    const [isClass, setClass] = useState(true)
    const [isCustomer, setCustomer] = useState(true)
    const [isCategory, setCategory] = useState(true)
    const [isSubcategory, setSubcategory] = useState(true)
    const [isBrand, setBrand] = useState(true)
    const [isSku, setSku] = useState(true)
    const [isUser, setUser] = useState(true)
    const [isDaily, setDaily] = useState(false)
    const [isWeekly, setWeekly] = useState(false)
    const [isMonthly, setMonthly] = useState(false)
    const [city_id, setCityId] = useState([])
    const [chain_id, setChainId] = useState([])
    const [classification_id, setClassificationId] = useState([])
    const [customer_id, setCustomerId] = useState([])
    const [category_id, setCategoryId] = useState([])
    const [brand_id, setBrandId] = useState([])
    const [sku_id, setSkuId] = useState([])
    const [user_id, setUserId] = useState([])
    const [dateRange, setDateRange] = useState('')
    const [dateRangeDisplayValue, setDateRangeDisplayValue] = useState('')


    useEffect(() => {
        // console.log(dateRange,'appcontext')
        setDateRangeDisplayValue(dateRangeParser(dateRange || []))
    }, [dateRange])

    return (
        <AppContext.Provider
            value={{
                city_id,
                setCityId,
                chain_id,
                setChainId,
                classification_id,
                setClassificationId,
                customer_id,
                setCustomerId,
                user_id,
                setUserId,
                category_id,
                setCategoryId,
                brand_id,
                setBrandId,
                sku_id,
                setSkuId,
                isDate,
                setDate,
                isRegion,
                setRegion,
                isCity,
                setCity,
                isAccount,
                setAccount,
                isChannel,
                setChannel,
                isClass,
                setClass,
                isCustomer,
                setCustomer,
                isCategory,
                setCategory,
                isSubcategory,
                setSubcategory,
                isBrand,
                setBrand,
                isSku,
                setSku,
                isUser,
                setUser,
                dateRange,
                setDateRange,
                dateRangeDisplayValue,
                isDaily,
                setDaily,
                isWeekly,
                setWeekly,
                isMonthly,
                setMonthly,
                
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
